<template>
  <div class="text-center">
    <v-menu
      v-model="menu"
      :close-on-content-click="false"
      :width="190"
      :nudge-bottom="44"
      bottom
      left
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          outlined
          v-bind="attrs"
          v-on="on"
          :disabled="disabled"
        >
          <v-icon left>mdi-table</v-icon>Visualización<v-icon size="18" right>mdi-chevron-down</v-icon>
        </v-btn>
      </template>

      <v-card>
        <v-card-text class="pa-5">
          <v-row align="center" no-gutters>
            <span class="grey-700--text subtitle-2 font-weight-semibold">Columnas</span>
            <!-- <v-tooltip color="grey-background" bottom max-width="160px" transition="scale-transition">
              <template v-slot:activator="{ on }">
                <span class="grey-500--text body-1" v-on="on">
                  <v-icon class="ml-2" small color="grey-500">mdi-information</v-icon>
                </span>
              </template>
              <span class="d-block px-3 py-2">Debes seleccionar mínimo 4 columnas y máximo 6 columnas para visualizar la tabla.</span>
            </v-tooltip> -->
          </v-row>
          <v-col cols="12" class="pa-0 mt-2" v-for="header in headersList" :key="header.value">
            <v-checkbox class="ma-0 vcheckbox" v-if="!['actions'].includes(header.value) && !header.fixed" color="blue-500" v-model="header.show" row :label="label(header)" dense hide-details />
          </v-col>
          <v-divider class="my-5" />
          <span class="grey-700--text subtitle-2 font-weight-semibold">Registros</span>
          <v-col cols="12" class="pa-0 mt-2" v-for="record in recordList" :key="record">
            <v-radio-group class="ma-0" v-model="pageSize" row absolute dense hide-details>
              <v-radio :label="record.toString()" color="blue-500" :value="record" />
            </v-radio-group>
          </v-col>
          <v-divider class="my-5" />
          <v-col class="text-center pa-0">
            <v-btn @click="save" color="blue-500" block :ripple="false">Aplicar</v-btn>
          </v-col>
        </v-card-text>
      </v-card>
    </v-menu>
  </div>
</template>
<script>

export default {
  props: {
    headers: {
      type: Array,
      default: () => ([])
    },
    object: {
      type: String,
      default: null
    },
    disabled: {
      type: Boolean,
      default: false
    },
    active: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    headersList: [],
    menu: false,
    pageSize: null,
    backup: [],
    recordList: [25, 50, 75, 100],
    min: 0,
    max: 0
  }),
  watch: {
    active: {
      handler (val) {
        this.menu = val
      },
      inmediate: true,
      deep: true
    },
    menu: {
      handler (val) {
       if (!val) this.$emit('update:active')
      },
      inmediate: true
    }
  },
  computed: {
    label () {
      return (header) => {
        if (header.value === 'number') return this.$t('generals.Folio #')
        if (header.label === 'Fecha val. SUNAT') return this.$t('generals.FechaSUNAT')
        return header.label
     }
    }
  },
  mounted () {
    const recovery = JSON.parse(localStorage.getItem('table-visualization-ax'))
    if (recovery?.length) {
      const index = recovery.findIndex((item) => item.object === this.object)
      if (index !== -1) {
        this.headersList = [...this.headers]
        this.headers.forEach((item) => {
          if (item.value !== 'actions') item.show = recovery[index].columns.includes(item.value)
        })
      }
      this.pageSize = recovery[index]?.page_size
      this.$emit('update:pageSize', this.pageSize)
    }
    this.headersList = this.headers

    this.$emit('update:headers', this.headersList)
  },
  methods: {
    checkColumn (val) {
      const countList = this.headersList.filter(({show, value}) => show && value !== 'actions').length
      this.$nextTick(() => {
        const index = this.headersList?.findIndex(({value}) => value === val)
        if (index >= 0) {
          if (countList < 4) {
            this.min += 1
            this.headersList[index].show = !this.headersList[index].show
            if (this.min === 1) {
              this.$dialog.message.error('Debes contar con un mínimo de 4 columnas para visualizar la tabla')
              setTimeout(() => {
                this.min = 0
              }, 1500)
            }
          } else if (countList > 6) {
            this.max += 1
            this.headersList[index].show = !this.headersList[index].show
            if (this.max === 1) {
              this.$dialog.message.error('Debes contar con un máximo de 6 columnas para visualizar la tabla')
              setTimeout(() => {
                this.max = 0
              }, 1500)
            }
          }
        }
      })
    },
    save () {
      const list = this.headersList.filter((item) => item.show)
      if (list.length > 3) this.$emit('update:headers', list)

      if (this.pageSize) {
        this.$emit('update:pageSize', this.pageSize)
      }

      if (list.length > 3 || this.pageSize) {
        this.backup = JSON.parse(localStorage.getItem('table-visualization-ax')) ?? []
        const index = this.backup?.findIndex((item) => item.object === this.object)
        if (index !== -1) {
          this.backup[index] = {
            object: this.object,
            columns: list.map(({value}) => value),
            page_size: this.pageSize
          }
        } else {
          this.backup = [
            ...this.backup,
            {
              object: this.object,
              columns: list.map(({value}) => value),
              page_size: this.pageSize
            }
          ]
        }
      localStorage.setItem('table-visualization-ax', JSON.stringify(this.backup))
      }

      this.menu = false
    }
  }
}
</script>